import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const AgendaPage = () => (
  <Layout>
    <Seo title="Agenda" />
    <section className="page-header">
      <h1>Agenda</h1>
    </section>
      <section className="page-intro">
          <div className="page-intro-content">
              <p>Wenst u een afspraak te maken voor een lenscontrole of oogmeting? Raadpleeg hieronder onze online agenda en boek meteen je afspraak op het moment dat voor jou het beste past.</p>
              <iframe src={"https://deleersnijder.oo2.online/"} className={"col-span-full h-[1100px] w-full min-h-screen"} />
          </div>
          <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
              <StaticImage
                  src="../images/over-ons_1.jpg"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Afspraak maken"
                  placeholder="blurred"
                  className="inline-block mx-2 w-12/12 h-full min-h-full"
              />
          </div>
          <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>
</Layout>
)

export default AgendaPage
